import React from "react";
const Details = () => {
  return (
    <div className="container" data-aos="fade-up" data-aos-duration={600}>
      <div className="">
        <h2 className="font-size-9 mb-4 pb-3">What You’ll Do</h2>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Design and optimize scalable web scrapers for static and dynamic
            websites
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Extract, process, and organize structured and unstructured data.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Implement smart solutions to tackle CAPTCHA, IP blocking, and
            dynamic content challenges.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Ensure scrapers are high-performing, scalable, and resilient.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Work with APIs, databases, and cloud storage for seamless data
            management.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Collaborate with product managers and developers to meet business
            needs.
          </li>
        </ul>

        <h2 className="font-size-9 mb-4 p-3">What We’re Looking For</h2>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Strong proficiency in <b>Python </b> and frameworks like
            <b>FastAPI, Django, or Flask.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Hands-on experience with
            <b>Scrapy, Selenium, BeautifulSoup, Playwright.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Expertise in handling large-scale data extraction and storage
            <b>(MySQL, PostgreSQL, MongoDB).</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Familiarity with
            <b>
              asynchronous programming, API interactions, and version control
              (Git).
            </b>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Details;
